<template>
  <div class="page-rooter-fff">
    <headeBar title="自动续费管理" left-arrow @click-left="newAppBack" />
    <div class="page-content">
      <van-tabs border swipeable v-model="active" title-active-color="#242831" title-inactive-color="#6C727A" color="#242831" line-height="0.53vw" line-width="42vw" @change="changeTab">
        <van-tab title="自动续费" name="INFO">

          <div class="list" v-if="cardInfo.equityCardUser">

            <div class="box">

              <!-- 芝麻进度 -->
              <div v-if="equityCardUserExtData.sesameNowCycleIndex" class="zhima-progress f24 fw4 flex">
                <p>芝麻先享进行中</p>
                <p>当前进度 <span style="color: #FF6E00;">{{ equityCardUserExtData.sesameNowCycleIndex }}</span>/{{ equityCardUserExtData.sesameAllCycleIndex }}</p>
              </div>
              <!-- 安心进度 -->
              <div v-if="equityCardUserExtData.aliSafePayNowCycleIndex" class="zhima-progress f24 fw4 flex">
                <p>安心付进行中</p>
                <p>当前进度 <span style="color: #FF6E00;">{{ equityCardUserExtData.aliSafePayNowCycleIndex }}</span>/{{ equityCardUserExtData.aliSafePayAllCycleIndex }}</p>
              </div>

              <div class="box-item">
                <div class="item" :style="`background-image: url(${cardInfo.equityCardUser.backgroundImgUrl})`">
                  <div>
                    <div class="name">{{ cardInfo.equityCardUser.cardName }}</div>
                  </div>
                  <div class="date-info row-start-end">
                    <div>
                      <p :style="{ color: (cardInfo.equityCardUser.status === 'ACTIVE' ? '#FFDE00' : '#FFFFFF') }">{{ cardInfo.equityCardUser.statusName }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.useValidityStr">
                      <p>{{ cardInfo.equityCardUser.useValidityStr }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.usePlace">
                      <p>{{ cardInfo.equityCardUser.usePlace }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.levelStr">
                      <p>{{ cardInfo.equityCardUser.levelStr }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.giveCardStr">
                      <p>{{ cardInfo.equityCardUser.giveCardStr }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.payType === 'AUTO_RENEW'">
                      <p> {{ equityCardUserExtData.isAutoRenew === 1 ? '自动续费（已开通）' : '自动续费（已关闭）' }} </p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.payType === 'ALI_PRE_ENJOY'">
                      <p class="blue" >{{ equityCardUserExtData.isAutoRenew === 1 ? '芝麻先享（已开通）' : '芝麻先享（已关闭）' }}</p>
                    </div>
                    <div v-if="cardInfo.equityCardUser.payType === 'ALI_SAFE_PAY'">
                      <p class="blue" >{{ equityCardUserExtData.isAutoRenew === 1 ? '安心付（已开通）' : '安心付（已关闭）' }}</p>
                    </div>

                  </div>

                  <div v-if="equityCardUserExtData.isAutoRenew === 1" @click="isCloseAutoRenewShow = true" class="button row-center-center f20">关闭自动续费</div>

                </div>

              </div>
            </div>

            <div>
              <div class="row-between-center">
                <p class="equity-date f28 fw6">权益列表</p>
              </div>
              <!-- 特殊权益处理 -->
              <div v-for="item in limitEquityList" :key="item.equityName">
                <!-- 团课 -->
                <div v-if="item.equityType === 1" class="cl-card">
                  <div class="row-between-start">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                  </div>
                  <div class="p1 f22">{{ item.equityInstructions }}</div>
                  <div class="coupon-list">
                    <div v-for="(vo, index) in item.vouchers" :key="index" class="coupon-item-box">
                      <div v-if="vo.type === '1'" class="coupon-item" :style="!(index % 4) ? 'margin-left: 0' : ''" :class="{ couponItemGray: (vo.status !== 0) }">
                        <h2><span>¥</span>{{ vo.price }}</h2>
                        <p>{{ vo.statusDescribe }}</p>
                      </div>
                      <div v-else class="big-coupon-item" :style="!(index % 2) ? 'margin-left: 0' : ''" :class="{ couponItemGray: (vo.status !== 0) }">
                        <div>
                          <h2><span>¥</span>{{ vo.price }}</h2>
                          <p>{{ vo.type | typeName }}</p>
                        </div>
                        <h4>{{ vo.statusDescribe }}</h4>
                      </div>
                    </div>
                  </div>
                  <p @click="goMyVoucher" v-if="cardInfo.equityCardUser.status !== 'WAIT_OPEN'" style="margin-top: 2.13vw;color: #FF6E00" class="status f24 row-start-center">已发放至优惠券账户<van-icon size="10" color="#FF6E00" name="arrow" /></p>
                </div>
                <!-- 私教 -->
                <div v-if="item.equityType === 5" class="cl-card">
                  <div class="row-between-start">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                  </div>
                  <div class="p1 f22">{{ item.equityInstructions }}</div>
                  <div class="per-equity">
                    <h3 class="row-center-end">
                      <span class="s1">¥</span>
                      <span class="s2">{{ item.per.price }}</span>
                      <span class="s3">私教课</span>
                    </h3>
                    <p class="row-center-center" v-if="cardInfo.equityCardUser.status === 'ACTIVE'">当前预约权益剩余 <span>{{
      item.per.residueLimitCount }}</span> 次，已使用 <span>{{ item.per.useLimitCount }}</span> 次，签到结课后恢复</p>
                    <div @click="openUseRecord" class="row-center-center" v-if="cardInfo.equityCardUser.status === 'ACTIVE'">查看预约的课程
                    </div>
                  </div>
                </div>
                <!-- 自助 -->
                <div v-if="item.isTimeLimitedDiscount === 1" class="cl-card">
                  <div class="row-between-start">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                    <p class="t2 f22 row-center-center" @click="goUseRecord">使用记录<van-icon color="#242831" name="arrow" /></p>
                  </div>
                  <div class="p1 f22">{{ item.equityInstructions }}</div>
                  <van-progress :percentage="((item.ticketTotalTime - item.ticketUsedTime) / item.ticketTotalTime) * 100" stroke-width="8" pivot-text="" />
                  <div class="row-between-center">
                    <p class="f22">剩余{{ (item.ticketTotalTime - item.ticketUsedTime) < 0 ? 0 : (item.ticketTotalTime - item.ticketUsedTime) }}分钟</p>
                        <p class="f22">共{{ item.ticketTotalTime }}分钟（{{ item.equityLabel }}）</p>
                  </div>
                </div>



                <!-- 包月上团课权益 -->
                <div v-if="item.equityType === 3 && item.scheduleFree" class="cl-card">
                  <div class="row-between-start">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                    <p class="t2 f22 row-center-center" @click="$router.push('/month-card/sign-rule')">上课签到规则<van-icon color="#242831" name="arrow" /></p>
                  </div>
                  <div class="p1 f22  ">{{ item.equityInstructions }}</div>
                  <div class="class-equity">


                    <div class="week-tips" :class="{ red: item.scheduleFree.isWarn }">
                      <span class="row-center-center">本周权益状态</span>
                      <h3 class="row-center-center">
                        <span v-if="!item.scheduleFree.isWarn">可无限次预约</span>
                        <span class="row-center-center" v-else>本周限制预约1节 <i class="row-center-center">已受限</i></span>
                      </h3>
                      <div v-if="item.scheduleFree.isWarn">过往周存在旷课，已影响本周权益状态，本周若保持0旷课，则下周权益恢复正常</div>
                    </div>


                    <div class="week-tips" :class="{ red: item.scheduleFree.truantCountOfWeek >= item.scheduleFree.warnTruantCount }">
                      <span class="row-center-center">本周旷课次数</span>
                      <h3 class="row-center-center">
                        <span v-if="item.scheduleFree.truantCountOfWeek < item.scheduleFree.warnTruantCount" class="s1 row-start-end"><span>{{ item.scheduleFree.truantCountOfWeek }}</span><span class="s2">次</span></span>
                        <span v-else class="s1 row-start-end"><span>{{ item.scheduleFree.truantCountOfWeek }}</span><span class="s2">次（下周将受限）</span></span>
                      </h3>
                      <div>数量≥{{ item.scheduleFree.warnTruantCount }}节则限制下周预约</div>
                    </div>
                    <div @click="jumpClassList()" class="row-center-center btn" v-if="cardInfo.equityCardUser.status === 'ACTIVE'">去预约
                    </div>
                  </div>
                </div>

                <!-- 包月上团课权益 4.0 升级 -->
                <div v-if="item.equityType === 3 && item.scheduleEspecially" class="cl-card">
                  <div class="row-between-start">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                    <p class="t2 f22 row-center-center" @click="$router.push('/month-card/sign-rule')">上课签到规则<van-icon color="#242831" name="arrow" /></p>
                  </div>
                  <div class="p1 f22">{{ item.equityInstructions }}</div>
                  <div class="class-equity" style="margin-top: 0">
                    <div v-if="item.scheduleEspecially.isShow" style="margin-top: 6.4vw" class="week-tips" :class="{ red: item.scheduleEspecially.isWarn }">
                      <span class="row-center-center">本周权益状态</span>
                      <h3 class="row-center-center">
                        <span class="row-center-center">{{ item.scheduleEspecially.equityUseDescribe }} <i v-if="item.scheduleEspecially.isWarn" class="row-center-center">已受限</i></span>
                      </h3>
                      <div v-if="item.scheduleEspecially.isWarn">过往周存在旷课，已影响本周权益状态，本周若保持0旷课，则下周权益恢复正常</div>
                    </div>

                    <div v-if="item.scheduleEspecially.isShow" class="week-tips" :class="{ red: item.scheduleEspecially.truantCountOfWeek >= item.scheduleEspecially.warnTruantCount }">
                      <span class="row-center-center">本周旷课次数</span>
                      <h3 class="row-center-center">
                        <span v-if="item.scheduleEspecially.truantCountOfWeek < item.scheduleEspecially.warnTruantCount" class="s1 row-start-end"><span>{{ item.scheduleEspecially.truantCountOfWeek }}</span><span class="s2">次</span></span>
                        <span v-else class="s1 row-start-end"><span>{{ item.scheduleEspecially.truantCountOfWeek }}</span><span class="s2">次（下周将受限）</span></span>
                      </h3>
                      <div>将影响下周免费课权益状态，数量≥{{ item.scheduleEspecially.warnTruantCount }}节则限制下周预约</div>
                    </div>
                    <div @click="jumpClassList()" class="row-center-center btn" v-if="cardInfo.equityCardUser.status === 'ACTIVE'">去预约
                    </div>
                  </div>
                </div>

              </div>
              <!-- 中峰段 赠团课 -->
              <div class="scroll-box">
                <div class="cl-card small flex-wrap flex-none" v-for="item in userEquityCardEquityVOList" :key="item.equityName">
                  <div class="row-between-center">
                    <p class="title f28 fw6">{{ item.equityName }}</p>
                  </div>
                  <div class="p1 f22" v-html="item.equityInstructions"></div>
                </div>
              </div>
            </div>
            <div class="row-between-center">
              <p class="equity-date f28 fw6">卡片信息</p>
            </div>
            <div class="cardInfo">
              <p>获得时间：{{ equityCardUserExtData.getTime }}</p>
              <p v-if="equityCardUserExtData.sourceStr">来源：{{ equityCardUserExtData.sourceStr }}</p>
              <p>是否可转赠：{{ equityCardUserExtData.isGiveOther ? '是' : '否' }}</p>
              <p v-if="equityCardUserExtData.validityTimeStr">生效期：{{ equityCardUserExtData.validityTimeStr }} <span v-if="equityCardUserExtData.isOrderOpen" @click="openDetail(equityCardUserExtData.byGivingEquityCardUserId)">查看主卡</span></p>
              <p v-if="equityCardUserExtData.freezeRemark">冻结说明：{{ equityCardUserExtData.freezeRemark }}</p>
            </div>
          </div>

          <div class="empty col-start-center" v-else>
            <div class="ad-box">
              <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/sell-membership-2022/renew/auto_renew1.png" alt="">
              <div @click="goBuy" class="button f22 row-center-center">去开通</div>
            </div>
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/sell-membership-2022/renew/auto_renew2.png" alt="">
          </div>
        </van-tab>
        <van-tab title="续费记录" name="LOSE">
          <div class="list" v-if="renewRecord.length">
            <div class="record-item row-between-center" v-for="item in renewRecord" :key="item.payTime">
              <div>
                <p class="f26 fw6">{{ item.subject }}</p>
                <p class="p2 f22">{{ item.payTime }}</p>
              </div>
              <div>
                <p class="f32 fw6">-¥{{ item.payAmount }}</p>
                <p class="p2 f22">{{ item.status === 2 ? '交易成功' : '交易失败' }}</p>
              </div>
            </div>
          </div>
          <div v-else class="empty col-center-center">
            <img class="empty-img" src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="">
            <div class="tip">暂无记录</div>
          </div>
        </van-tab>
      </van-tabs>
    </div>

    <van-popup v-model="isCloseAutoRenewShow" position="center" class="popsure">
      <div class="sure-title f36 fw6">提示</div>
      <div class="sure-content f24">{{ hasClosed ? `关闭成功，您的超鹿月卡将于${validityEndDate}到期。` : '是否确认关闭自动续费？关闭后将不再发起自动扣款申请。' }}</div>

      <div v-if="hasClosed" class="row-between-center">
        <div class="sure-sub flex-auto f32 fw6" @click="isCloseAutoRenewShow = false">我知道了</div>
      </div>
      <div v-else class="row-between-center">
        <div class="sure-sub f32 cancel" @click="closeAutoRenew">确定关闭</div>
        <div class="sure-sub f32 fw6" @click="isCloseAutoRenewShow = false">继续包月</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb, goFreeClassList } from "@/lib/appMethod"
import headeBar from '@/components/app/headBar'

import wx from "weixin-js-sdk"

export default {
  data() {
    return {
      renewRecord: [],
      cardInfo: {},
      limitEquityList: [], // 限制的权益
      userEquityCardEquityVOList: [],
      equityCardUserExtData: {}, // 额外信息

      active: 0,

      param: {
        token: '',
        userId: '',
      },

      isCloseAutoRenewShow: false, // 关闭自动续费确认弹窗
      hasClosed: false, // 是否关闭成功
      validityEndDate: '', // 关闭时显示到期时间
    };
  },
  mixins: [userMixin],
  components: { headeBar },
  async created() {
    initBack(); //劫持后退
    await this.$getAllInfo();
    this.param.userId = this.userId
    this.param.token = this.token

    this.getCardInfo()
    this.getRenewRecord()
  },
  mounted() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.getCardInfo()
      }
    })
  },

  methods: {
    newAppBack,
    changeTab(v) {
      if (v === 'INFO') this.getCardInfo()
    },

    // 获取卡列表
    getCardInfo() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      });
      this.$axios.post(`${this.baseURLApp}/user/equityCard/myAutoRenewEquityCardSearch`, this.param).then(res => {
        this.cardInfo = res.data

        // 处理权益项
        this.limitEquityList = []
        this.userEquityCardEquityVOList = []

        res.data.equities.map((item) => {
          let condition = item.isTimeLimitedDiscount === 1 ||
            (item.equityType === 1 && item.vouchers !== undefined) ||
            (item.equityType === 5 && item.per !== undefined) ||
            (item.equityType === 3 && item.scheduleFree !== undefined) ||
            (item.equityType === 3 && item.scheduleEspecially !== undefined)
          condition ? this.limitEquityList.push(item) : this.userEquityCardEquityVOList.push(item)
        })

        this.equityCardUserExtData = res.data.equityCardUserExtData // 权益卡额外信息

      }).finally(r => {
        this.$toast.clear()
      })
    },
    getRenewRecord() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      })
      this.$axios.post(`${this.baseURLApp}/order/cyclePay/list`, {
        userId: this.userId,
        extraType: '25'
      }).then(res => {
        this.renewRecord = res.data || []
      }).finally(r => {
        this.$toast.clear()
      })
    },
    goBuy() {
      appOpenWeb('', `${window.location.origin}/#/superdeer-activity/sell-membership`)
    },
    goUseRecord() {
      appOpenWeb('', `${window.location.origin}/#/month-card/use-record-self?userMembershipCardId=${this.cardInfo.equityCardUser.equityCardUserId}`)
    },
    // 跳转超鹿月卡免费团课页面
    jumpClassList() {
      goFreeClassList()
    },
    closeAutoRenew() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
      })
      this.$axios.post(`${this.baseURLApp}/user/equityCard/equityCardAutoRenewCancel`, {
        equityCardUserId: this.cardInfo.equityCardUser.equityCardUserId,
        ...this.param
      }).then(res => {
        this.validityEndDate = res.data.validityEndDate
        this.hasClosed = true
        this.getCardInfo()
        this.$toast.clear()
      })
    }
  },
};
</script>

<style lang="less" scoped>
.page-rooter-fff {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F7F7F7;

  * {
    box-sizing: border-box;
  }

  .page-content {
    //flex-grow: 1;
    flex: 1 1 0;
    overflow: hidden;
  }

  .com_headerbar {
    flex-shrink: 0;
  }
}

.empty {
  padding: 48px 32px 32px;
  min-height: 50vh;
  box-sizing: border-box;

  img {
    width: 100%;
  }

  .empty-img {
    width: 320px;
    height: 320px;
  }

  .tip {
    text-align: center;
    margin-top: 20px;
    color: #6C727A;
  }

  .ad-box {
    width: 100%;
    position: relative;
    margin: 0 0 70px;

    .button {
      width: 110px;
      height: 54px;
      background: #FFFFFF;
      border-radius: 27px;
      color: #FF5D3C;
      position: absolute;
      right: 20px;
      top: 30px;
    }
  }
}

.list {
  height: 100%;
  width: 100%;
  padding: 0 0 150px;
  box-sizing: border-box;
  overflow-y: auto;

  .box {
    padding: 24px 32px 32px;
    background-color: #fff;
    .zhima-progress {
      padding: 18px 4px 20px;
      color: #242831;
    }
  }

  .box-item+.box-item {
    margin-top: 32px;
  }

  .box-item {
    border-radius: 16px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border: 1px solid #eee;

    .item {
      width: 100%;
      height: 320px;
      display: flex;
      padding-left: 28px;
      padding-top: 24px;
      box-sizing: border-box;
      position: relative;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .button {
        width: 156px;
        height: 44px;
        background: #FFFAED;
        border-radius: 22px;
        position: absolute;
        right: 28px;
        top: 30px;
      }

      .name {
        font-size: 44px;
        font-family: PuHuiTi;
        color: #FFFFFF;
        width: 8em;
        line-height: 52px;
        margin-bottom: 26px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        -webkit-background-clip: text;
      }

      .renewal {
        width: 152px;
        height: 58px;
        background: #3C454E;
        border-radius: 4px;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 24px;
      }

      .use-tips {
        font-size: 22px;
        color: #FFFFFF;
        line-height: 26px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }

      .date-info {
        position: absolute;
        bottom: 28px;
        left: 28px;
        width: 622px;
        div {
          flex: none;
        }
        p {
          color: #FFFFFF;
          font-weight: bold;
          height: 36px;
          font-size: 20px;
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.5);
          padding: 0 12px;
          display: flex;
          align-items: center;
          margin-right: 16px;

          &.blue {
            background: #1777FF;
          }
        }
      }

      .status {
        position: absolute;
        top: 40px;
        right: 32px;
        font-weight: bold;
        color: #FFF040;
        font-size: 24px;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 0 12px;
        height: 44px;
        line-height: 44px;
        border-radius: 8px;

        &.gray {
          color: #9aa1a9;
        }
      }
    }
  }


  .record-item {
    width: 686px;
    margin: 24px auto 0;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 32px;
    .p2 {
      margin: 20px 0 0;
    }
  }

  .equity-date {
    padding: 0 32px;
    margin-top: 48px;
    color: #242831;
  }

  .cl-card {
    width: 686px;
    padding: 40px 28px 40px;
    background: #ffffff;
    border-radius: 16px;
    margin: 24px auto 0;
    overflow: hidden;

    .title {
      max-width: 470px;
    }

    .p1 {
      line-height: 34px;
      margin: 16px 0 0;
      color: #242831;
    }

    .van-progress {
      margin: 40px 0 24px;
    }

    .status {
      margin: 40px 0 0;
    }

    .van-icon {
      color: #9aa1a9;
      font-weight: 600;
      margin: 0 0 0 6px;
    }
  }
}

.class-equity {
  margin-top: 48px;

  .btn {
    margin-top: 48px;
    width: 630px;
    height: 80px;
    background: #FFDE00;
    border-radius: 6px;
    font-weight: bold;
    font-size: 26px;
  }

  .week-tips {
    width: 630px;
    background: #F0F4FF;
    border-radius: 8px;
    padding: 90px 20px 44px;
    position: relative;

    &+.week-tips {
      margin-top: 28px;
    }

    &.red {

      background: #FFF1ED;

      h3 {
        color: #F03C18;

        i {
          margin-left: 8px;
          font-style: normal;
          width: 92px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid #F03C18;
          line-height: 24px;
          font-size: 24px;
        }
      }

      &>span {
        background: rgba(240, 60, 24, 0.78);
      }
    }

    &>span {
      width: 172px;
      height: 46px;
      background: rgba(38, 125, 255, 0.78);
      border-radius: 8px 0 8px 0;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 1;
      font-weight: 600;
      color: #FFFFFF;
      font-size: 22px;
      line-height: 22px;
    }

    h3 {
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      color: #267DFF;

      .s1 {
        font-size: 42px;
        line-height: 42px;
      }

      .s2 {
        font-size: 26px;
        line-height: 30px;
      }
    }

    div {
      text-align: center;
      margin-top: 24px;
      font-size: 22px;
      line-height: 34px;
      color: #242831;
    }
  }
}

.cardInfo {
  width: 686px;
  background: #FFFFFF;
  border-radius: 12px;
  padding: 32px 28px;
  margin: 24px auto 0;

  p {
    color: #242831;
    font-size: 22px;
    line-height: 34px;

    &+p {
      margin-top: 8px;
    }

    span {
      background: right center url("https://img.chaolu.com.cn/ACT/icon/arrow_back_gray.png") no-repeat;
      background-size: 22px 22px;
      padding-right: 26px;
      margin-left: 16px;
    }
  }
}

.popsure {
  width: 590px;
  border-radius: 12px;
  padding: 50px 40px;

  .sure-title {
    color: #242831;
    text-align: center;
  }

  .sure-content {
    color: #3C454E;
    margin: 30px 0 56px;
    line-height: 1.8;
    text-align: center;
  }

  .sure-sub {
    width: 244px;
    border-radius: 8px;
    background: #FFDE00;
    color: #000;
    line-height: 96px;
    text-align: center;

    &.cancel {
      background: #fff;
      line-height: 94px;
      border: 1px solid #D9D9D9;
      color: #3C454E;
    }
  }
}

/deep/ .van-tab--active {
  font-weight: bold;
}

/deep/ .van-tabs {
  height: 100%;
}

/deep/ .van-hairline--top-bottom::after {
  border-top: none;
  border-color: #eee;
}

/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: calc(100% - 44px);
  display: flex;
  flex-direction: column;
}
</style>
